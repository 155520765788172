<template>
  <!-- 人工认证 -->
  <div id="app">
    <div class="center-tit">
      CSON官方人工认证
      <span>/Identity Certification</span>
    </div>
    <div class="account-con">
      <div class="cson-cer">
        <div class>
          <img src="@/assets/center/cer-icon.png" />本次认证由CSON官方审核认证，
          <span>8小时人工审核</span>
        </div>
      </div>
      <div class="cer-form">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="真实姓名">
            <el-input
              v-model="form.realName"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="证件号">
            <el-input
              v-model="form.certificationNumber"
              placeholder="请输入证件号"
            ></el-input>
          </el-form-item>
          <el-form-item label="出生日期">
            <el-date-picker
              v-model="form.birthday"
              placeholder="选择日期"
              type="date"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="证件类型">
            <el-select
              v-model="form.certificationType"
              placeholder="请选择证件类型"
              @change="isCertificationType"
            >
              <el-option label="身份证" value="0"></el-option>
              <el-option label="护照" value="1"></el-option>
              <el-option label="国外护照" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label>
            <el-upload
              v-if="form.certificationType == 0"
              ref="idcard"
              :file-list="picture"
              :headers="headers"
              :on-change="uploadChange"
              :on-success="($event) => handleUploadSuccess($event, 'picture')"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :action="uploadUrl"
              :before-upload="
                (file) => {
                  return beforeUpload(file, 0);
                }
              "
              class="upload-demo upload-idcode"
              drag
              show-file-list
            >
              <img src="@/assets/center/upload-icon.png" />
              <div class="el-upload__text">
                <p>文件拖放区</p>
                <p>请上传身份证正面</p>
              </div>
            </el-upload>

            <el-upload
              v-if="form.certificationType == 0"
              ref="backPicture"
              :file-list="backPicture"
              :headers="headers"
              :on-change="uploadChange"
              :on-success="
                ($event) => handleUploadSuccess($event, 'backPicture')
              "
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :action="uploadUrl"
              :before-upload="
                (file) => {
                  return beforeUpload(file, 1);
                }
              "
              class="upload-demo"
              drag
              show-file-list
            >
              <img src="@/assets/center/upload-icon.png" />
              <div class="el-upload__text">
                <p>文件拖放区</p>
                <p>请上传身份证反面</p>
              </div>
            </el-upload>

            <el-upload
              v-if="form.certificationType == 1"
              ref="passport"
              :file-list="passport"
              :headers="headers"
              :on-change="uploadChange"
              :on-success="($event) => handleUploadSuccess($event, 'picture')"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :action="uploadUrl"
              :before-upload="
                (file) => {
                  return beforeUpload(file, 2);
                }
              "
              class="upload-demo upload-idcode"
              drag
              show-file-list
            >
              <img src="@/assets/center/upload-icon.png" />
              <div class="el-upload__text">
                <p>文件拖放区</p>
                <p>请上传护照</p>
              </div>
            </el-upload>

            <el-upload
              v-if="form.certificationType == 2"
              ref="abroadPassport"
              :file-list="abroadPassport"
              :headers="headers"
              :on-change="uploadChange"
              :on-success="($event) => handleUploadSuccess($event, 'picture')"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :action="uploadUrl"
              :before-upload="
                (file) => {
                  return beforeUpload(file, 3);
                }
              "
              class="upload-demo upload-idcode"
              drag
              show-file-list
            >
              <img src="@/assets/center/upload-icon.png" />
              <div class="el-upload__text">
                <p>文件拖放区</p>
                <p>请上传国外护照</p>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="手持证件照">
            <el-upload
              ref="selfPicture"
              :file-list="selfPicture"
              :headers="headers"
              :on-change="uploadChange"
              :on-success="
                ($event) => handleUploadSuccess($event, 'selfPicture')
              "
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :action="uploadUrl"
              :before-upload="
                (file) => {
                  return beforeUpload(file, 4);
                }
              "
              class="upload-demo"
              drag
              show-file-list
            >
              <img src="@/assets/center/upload-icon.png" />
              <div class="el-upload__text">
                <p>文件拖放区</p>
                <p>手持照片五官完整入镜上传</p>
              </div>
            </el-upload>
          </el-form-item>
          <div class="submit" style="margin-left: 92px">
            <p>·您提供的文件必须清晰有效</p>
            <p>
              ·我们将按照
              <router-link
                :to="{ path: '/question', query: { TabActive: 7 } }"
                target="_blank"
                >《隐私政策》
              </router-link>
              处理您的信息
            </p>
            <p>·JPG GIF PNG | 每个文件不能超过5MB</p>
            <el-button @click="onPostCertification">提交审核</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div v-if="dialogFormVisible" class="submit-con">
      <div class="box">
        <img src="@/assets/center/bingo-icon.png" />
        <h2>待审核</h2>
        <h2>
          认证资料将在
          <span>8小时内</span>审核完成
        </h2>
        <button @click="dialogFormVisible = false">完成</button>
      </div>
    </div>
  </div>
</template>
<script>
import { postCertification } from "../../../service/index";
import { getIn } from "../../../utils/index";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      form: {
        birthday: "", // 生日
        certificationNumber: "", // 证件号
        certificationType: "0", // 证件类型,可用值:alipay,americanDriverLicense,idcard,passport,schoolRoll
        picture: "", // 证件照地址
        backPicture: "",
        realName: "", // 真实姓名
        selfPicture: "", // 自拍照地址
      },
      dialogFormVisible: false,
      headers: {},
      justIdCard: [],
      backIdCard: [],
      backPicture: [],
      picture: [],
      passport: [],
      abroadPassport: [],
      selfPicture: [],
      shStatus: "",
      uploadUrl: "",
    };
  },
  watch: {
    "$store.state.userInfo.status.value"(newVal, oldVal) {
      this.shStatus = newVal;
    },
  },
  created() {
    this.headers = {
      "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
    };
    this.shStatus = this.$store.state.userInfo.status.value;
  },
  mounted() {},
  methods: {
    uploadChange(file, fileList) {
      // 验证文件数量，替换后仅保留第一个文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    // 上传图片成功回调
    async handleUploadSuccess(e, key) {
      // console.log(e, key,111111111);

      if (e.success) {
        if (e.result.status != true) {
          this.$message.warning("请保证正确证件并保证清晰度！");
        }
        // if (key=='picture') {
        //   this.picture = []
        //   this.passport = []
        //   this.abroadPassport = []
        // }
        // if (key=='backPicture') {
        //   this.backPicture=[]
        // }
        const objectKey = getIn(e, ["result", "objectKey"]);
        if (objectKey) {
          this.form[key] = objectKey;
        }
      } else {
        const message = getIn(e, ["message"]) || "系统繁忙，请稍后再试";
        this.$message.error(message);
      }
    },
    beforeUpload(file, index) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        return this.$message.error("上传图片大小不能超过 10MB!");
      }
      return new Promise((resolve, reject) => {
        // 拼接上传url
        this.uploadUrl =
          process.env.VUE_APP_BASE_URL +
          "/certification/getOcr?ocrType=" +
          index +
          "&needCut=false";
        // dom上传地址更新完成后，触发上传
        this.$nextTick(() => resolve());
      });
    },
    isCertificationType() {
      this.selfPicture = [];
    },
    // 提交审核
    async onPostCertification() {
      this.$message.closeAll();
      const {
        birthday, // 生日
        certificationNumber,
        certificationType, // 证件类型,可用值:alipay,americanDriverLicense,idcard,passport,schoolRoll
        picture, // 证件照地址
        realName, // 真实姓名
        selfPicture, // 自拍照地址
        backPicture,
      } = this.form;
      // console.log(this.form);
      if (!realName) {
        this.$message.error("请输入您的真实姓名");
        return;
      }
      let nameExp = /^[\u4e00-\u9fa5]+$/;
      if (!nameExp.test(realName)) {
        this.$message.error("真实姓名请输入汉字！");
        return;
      }
      if (!certificationNumber) {
        this.$message.error("请输入您的证件号");
        return;
      }
      if (!birthday) {
        this.$message.error("请选择您的生日日期");
        return;
      }
      if (!certificationType) {
        this.$message.error("请选择您的证件类型");
        return;
      }
      if (!picture) {
        this.$message.error(
          `请上传${certificationType == 0 ? "身份证正面" : "护照页"}图片`
        );
        return;
      }
      if (certificationType == 0 && !backPicture) {
        this.$message.error("请上传身份证反面图片");
        return;
      }
      if (!selfPicture) {
        this.$message.error("需要上传您的手持证件照");
        return;
      }

      let captchaId = "2060393098";

      const captcha = new TencentCaptcha(captchaId, async (res) => {
        if (res.ret === 0) {
          const result = await postCertification(this.form);
          if (result && result.code == 200 && result.success) {
            // this.shStatus = 1;
            setTimeout(() => {
              location.href = "#/center/certificate";
            }, 100);
            this.$store.state.userInfo.status.value = 1;
          } else {
            this.$message.error(
              getIn(result, ["message"]) || "系统繁忙，请稍后再试"
            );
          }
          // console.log("result:", result);
        }
      });
      captcha.show();
    },
  },
};
</script>

<style>
.upload-idcode li:first-child {
  margin-bottom: 15px !important;
}
</style>

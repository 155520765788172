var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_vm._m(0),_c('div',{staticClass:"account-con"},[_vm._m(1),_c('div',{staticClass:"cer-form"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"真实姓名"}},[_c('el-input',{attrs:{"placeholder":"请输入真实姓名"},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}})],1),_c('el-form-item',{attrs:{"label":"证件号"}},[_c('el-input',{attrs:{"placeholder":"请输入证件号"},model:{value:(_vm.form.certificationNumber),callback:function ($$v) {_vm.$set(_vm.form, "certificationNumber", $$v)},expression:"form.certificationNumber"}})],1),_c('el-form-item',{attrs:{"label":"出生日期"}},[_c('el-date-picker',{attrs:{"placeholder":"选择日期","type":"date","value-format":"yyyy-MM-dd"},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}})],1),_c('el-form-item',{attrs:{"label":"证件类型"}},[_c('el-select',{attrs:{"placeholder":"请选择证件类型"},on:{"change":_vm.isCertificationType},model:{value:(_vm.form.certificationType),callback:function ($$v) {_vm.$set(_vm.form, "certificationType", $$v)},expression:"form.certificationType"}},[_c('el-option',{attrs:{"label":"身份证","value":"0"}}),_c('el-option',{attrs:{"label":"护照","value":"1"}}),_c('el-option',{attrs:{"label":"国外护照","value":"2"}})],1)],1),_c('el-form-item',{attrs:{"label":""}},[(_vm.form.certificationType == 0)?_c('el-upload',{ref:"idcard",staticClass:"upload-demo upload-idcode",attrs:{"file-list":_vm.picture,"headers":_vm.headers,"on-change":_vm.uploadChange,"on-success":($event) => _vm.handleUploadSuccess($event, 'picture'),"accept":".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP","action":_vm.uploadUrl,"before-upload":(file) => {
                return _vm.beforeUpload(file, 0);
              },"drag":"","show-file-list":""}},[_c('img',{attrs:{"src":require("@/assets/center/upload-icon.png")}}),_c('div',{staticClass:"el-upload__text"},[_c('p',[_vm._v("文件拖放区")]),_c('p',[_vm._v("请上传身份证正面")])])]):_vm._e(),(_vm.form.certificationType == 0)?_c('el-upload',{ref:"backPicture",staticClass:"upload-demo",attrs:{"file-list":_vm.backPicture,"headers":_vm.headers,"on-change":_vm.uploadChange,"on-success":($event) => _vm.handleUploadSuccess($event, 'backPicture'),"accept":".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP","action":_vm.uploadUrl,"before-upload":(file) => {
                return _vm.beforeUpload(file, 1);
              },"drag":"","show-file-list":""}},[_c('img',{attrs:{"src":require("@/assets/center/upload-icon.png")}}),_c('div',{staticClass:"el-upload__text"},[_c('p',[_vm._v("文件拖放区")]),_c('p',[_vm._v("请上传身份证反面")])])]):_vm._e(),(_vm.form.certificationType == 1)?_c('el-upload',{ref:"passport",staticClass:"upload-demo upload-idcode",attrs:{"file-list":_vm.passport,"headers":_vm.headers,"on-change":_vm.uploadChange,"on-success":($event) => _vm.handleUploadSuccess($event, 'picture'),"accept":".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP","action":_vm.uploadUrl,"before-upload":(file) => {
                return _vm.beforeUpload(file, 2);
              },"drag":"","show-file-list":""}},[_c('img',{attrs:{"src":require("@/assets/center/upload-icon.png")}}),_c('div',{staticClass:"el-upload__text"},[_c('p',[_vm._v("文件拖放区")]),_c('p',[_vm._v("请上传护照")])])]):_vm._e(),(_vm.form.certificationType == 2)?_c('el-upload',{ref:"abroadPassport",staticClass:"upload-demo upload-idcode",attrs:{"file-list":_vm.abroadPassport,"headers":_vm.headers,"on-change":_vm.uploadChange,"on-success":($event) => _vm.handleUploadSuccess($event, 'picture'),"accept":".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP","action":_vm.uploadUrl,"before-upload":(file) => {
                return _vm.beforeUpload(file, 3);
              },"drag":"","show-file-list":""}},[_c('img',{attrs:{"src":require("@/assets/center/upload-icon.png")}}),_c('div',{staticClass:"el-upload__text"},[_c('p',[_vm._v("文件拖放区")]),_c('p',[_vm._v("请上传国外护照")])])]):_vm._e()],1),_c('el-form-item',{attrs:{"label":"手持证件照"}},[_c('el-upload',{ref:"selfPicture",staticClass:"upload-demo",attrs:{"file-list":_vm.selfPicture,"headers":_vm.headers,"on-change":_vm.uploadChange,"on-success":($event) => _vm.handleUploadSuccess($event, 'selfPicture'),"accept":".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP","action":_vm.uploadUrl,"before-upload":(file) => {
                return _vm.beforeUpload(file, 4);
              },"drag":"","show-file-list":""}},[_c('img',{attrs:{"src":require("@/assets/center/upload-icon.png")}}),_c('div',{staticClass:"el-upload__text"},[_c('p',[_vm._v("文件拖放区")]),_c('p',[_vm._v("手持照片五官完整入镜上传")])])])],1),_c('div',{staticClass:"submit",staticStyle:{"margin-left":"92px"}},[_c('p',[_vm._v("·您提供的文件必须清晰有效")]),_c('p',[_vm._v(" ·我们将按照 "),_c('router-link',{attrs:{"to":{ path: '/question', query: { TabActive: 7 } },"target":"_blank"}},[_vm._v("《隐私政策》 ")]),_vm._v(" 处理您的信息 ")],1),_c('p',[_vm._v("·JPG GIF PNG | 每个文件不能超过5MB")]),_c('el-button',{on:{"click":_vm.onPostCertification}},[_vm._v("提交审核")])],1)],1)],1)]),(_vm.dialogFormVisible)?_c('div',{staticClass:"submit-con"},[_c('div',{staticClass:"box"},[_c('img',{attrs:{"src":require("@/assets/center/bingo-icon.png")}}),_c('h2',[_vm._v("待审核")]),_vm._m(2),_c('button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v("完成")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-tit"},[_vm._v(" CSON官方人工认证 "),_c('span',[_vm._v("/Identity Certification")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cson-cer"},[_c('div',{},[_c('img',{attrs:{"src":require("@/assets/center/cer-icon.png")}}),_vm._v("本次认证由CSON官方审核认证， "),_c('span',[_vm._v("8小时人工审核")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v(" 认证资料将在 "),_c('span',[_vm._v("8小时内")]),_vm._v("审核完成 ")])
}]

export { render, staticRenderFns }